import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, searchFeatureKey } from './search.reducer';
import { SearchConfig } from '@models/search-models';

const selectSearchState = createFeatureSelector<State>(searchFeatureKey);

// Raw selectors
const selectSearchDisplay = createSelector(selectSearchState, (state: State) => state.searchDisplay);
const selectSearchSource = createSelector(selectSearchState, (state: State) => state.searchSource);

export const selectDataStructures = createSelector(selectSearchState, (state: State) => state.dataStructures);

const selectDataStructureId = createSelector(selectSearchState, (state: State) => state.dataStructureId);

export const selectSearchables = createSelector(selectSearchState, (state: State) => state.searchables);

export const selectSearchQuery = createSelector(selectSearchState, (state: State) => state.searchQuery);

export const selectIndametaMetrics = createSelector(selectSearchState, (state: State) => state.indametaMetrics);

export const selectIndametaHistory = createSelector(selectSearchState, (state: State) => state.indametaHistory);

export const selectDatabases = createSelector(selectSearchState, (state: State) => state.databases);

export const selectDatabase = createSelector(selectSearchState, (state: State) => state.database);

export const selectIndabaFilter = createSelector(selectSearchState, (state: State) => state.indabaFilter);

export const selectIndabaMetrics = createSelector(selectSearchState, (state: State) => state.indabaMetrics);

export const selectIndabaHistory = createSelector(selectSearchState, (state: State) => state.indabaHistory);

export const selectPagination = createSelector(selectSearchState, (state: State) => state.pagination);

export const selectHasMoreMetrics = createSelector(selectSearchState, (state: State) => !state.pagination?.isComplete);

export const selectTotalMetrics = createSelector(selectSearchState, (state: State) => state.pagination?.total);

export const selectSelectedMetrics = createSelector(selectSearchState, (state: State) => state.selectedMetrics);

export const selectMetricsLatests = createSelector(selectSearchState, (state: State) => state.metricsLatests);

export const selectFavorites = createSelector(selectSearchState, (state: State) => state.favorites);

export const selectSelectedFavorite = createSelector(selectSearchState, (state: State) => state.selectedFavorite);

export const selectFavoritesName = createSelector(selectSearchState, (state: State) => state.favorites.map(s => s.name.toLocaleLowerCase()));

export const selectFavoritesHomepage = createSelector(selectSearchState, (state: State) => {
    return [...state.favorites].sort((a, b) => {
        const aDate = new Date(a.lastVisit);
        const bDate = new Date(b.lastVisit);
        
        return bDate.getTime() - aDate.getTime();
    }).splice(0, 3);
});

// Combined selectors
export const selectSearchConfig = createSelector(
    selectSearchDisplay,
    selectSearchSource,
    (display, source) => ({ display, source } as SearchConfig)
);

export const selectDataStructure = createSelector(
    selectDataStructures,
    selectDataStructureId,
    (dataStructures, id) => dataStructures.find(ds => ds.id === id) ?? null
);
