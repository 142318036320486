import { Pagination } from '@models/api-models';
import { DataStructure, IndametaItem, SearchQueryItem, SearchableProperty } from '@models/indameta-models';
import { Metric, MetricWithValues } from '@models/indaba-models';
import { SearchDisplay, SearchHistoryIndaba, SearchHistoryIndameta, SearchSource } from '@models/search-models';
import { createAction, props } from '@ngrx/store';
import { SearchFavorite } from '@models/search-models/search-favorite';

export const setSearchDisplay = createAction('[Search] Set search display', props<{ searchDisplay: SearchDisplay }>());
export const setSearchSource = createAction('[Search] Set search source', props<{ searchSource: SearchSource }>());

export const getDataSturctures = createAction('[Search] Get data structures');
export const getDataSturcturesSuccess = createAction('[Search] Get data structures Success', props<{ dataStructures: DataStructure[] }>());
export const getDataSturcturesError = createAction('[Search] Get data structures Error');

export const setDataStructure = createAction('[Search] Set current data structure', props<{ dataStructure: DataStructure }>());
export const getSearchablesSuccess = createAction('[Search] Get searchables Success', props<{ searchables: SearchableProperty[] }>());
export const getSearchablesError = createAction('[Search] Get searchables Error');

export const addSearchQueryItem = createAction('[Search] Add search query item', props<{ searchQueryItem: SearchQueryItem }>());
export const removeSearchQueryItem = createAction('[Search] Remove search query item', props<{ searchQueryItem: SearchQueryItem }>());

export const getDatabases = createAction('[Search] Get databases');
export const getDatabasesSuccess = createAction('[Search] Get databases success', props<{ databases: string[] }>());
export const getDatabasesError = createAction('[Search] Get databases error');

export const setDatabase = createAction('[Search] Set database', props<{ database: string }>());
export const setIndabaFilter = createAction('[Search] Set indaba filter', props<{ indabaFilter: string }>());

export const searchMetrics = createAction('[Search] Get metrics');
export const getNextMetrics = createAction('[Search] Get next metrics');

export const searchMetricsIndaMeta = createAction('[Search] Get metrics IndaMeta');
export const searchMetricsIndaMetaSuccess = createAction('[Search] Get metrics IndaMeta Success', props<{ indametaMetrics: IndametaItem[], pagination: Pagination, history: SearchHistoryIndameta }>());
export const searchMetricsIndaMetaError = createAction('[Search] Get metrics IndaMeta Error');

export const getNextMetricsIndaMeta = createAction('[Search] Get next metrics IndaMeta');
export const getNextMetricsIndaMetaSuccess = createAction('[Search] Get next metrics IndaMeta Success', props<{ indametaMetrics: IndametaItem[], pagination: Pagination }>());
export const getNextMetricsIndaMetaError = createAction('[Search] Get next metrics IndaMeta Error');

export const searchFromHistoryIndaMeta = createAction('[Search] Get metrics from history IndaMeta', props<{ history: SearchHistoryIndameta }>());

export const searchMetricsIndaba = createAction('[Search] Get metrics Indaba');
export const searchMetricsIndabaSuccess = createAction('[Search] Get metrics Indaba Success', props<{ indabaMetrics: Metric[], pagination: Pagination, history: SearchHistoryIndaba }>());
export const searchMetricsIndabaError = createAction('[Search] Get metrics Indaba Error');

export const getNextMetricsIndaba = createAction('[Search] Get next metrics Indaba');
export const getNextMetricsIndabaSuccess = createAction('[Search] Get next metrics Indaba Success', props<{ indabaMetrics: Metric[], pagination: Pagination }>());
export const getNextMetricsIndabaError = createAction('[Search] Get next metrics Indaba Error');

export const searchFromHistoryIndaba = createAction('[Search] Get metrics from history Indaba', props<{ history: SearchHistoryIndaba }>());

export const selectMetric = createAction('[Search] Select metric', props<{ metric: Metric | IndametaItem }>());
export const resetSelectedMetrics = createAction('[Search] Reset selected metrics');
export const goToCompareMetrics = createAction('[Search] Compare metrics');

export const getLatests = createAction('[Search] Get latests');
export const getLatestsSuccess = createAction('[Search] Get latests success', props<{ metricsLatests: MetricWithValues[] }>());
export const getLatestsError = createAction('[Search] Get latests error');

export const exploreMetric = createAction('[Search] Explore metric', props<{ metric: MetricWithValues }>());
export const removeMetricFromSelection = createAction('[Search] Remove metric from selection', props<{ metric: MetricWithValues }>());

export const resetSearchDisplay = createAction('[Search] Reset search display');
export const saveToFavorite = createAction('[Search] Save to favorite', props<{ favorite: SearchFavorite }>());
export const updateFavorite = createAction('[Search] Update favorite', props<{ favorite: SearchFavorite, newMetrics: MetricWithValues[] }>());
export const goToFavorite = createAction('[Search] Go to favorite', props<{ favorite: SearchFavorite }>());
export const deleteFavorite = createAction('[Search] Delete favorite', props<{ favorite: SearchFavorite }>());
export const goToFavoritePage = createAction('[Search] Go to favorite page');