import { DateTimePeriodString } from '@models/dateTimePeriod';
import { Metric, Value, ValueSerializable } from '@models/indaba-models';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { SearchActions } from '../../search/store';
import * as ExplorerActions from './explorer.actions';

export const explorerFeatureKey = 'explorer';

export interface State {
    metric: Metric;
    values: Value[];
    dateTimePeriod: DateTimePeriodString;
    isAggregated: boolean;
    graphValue: ValueSerializable;
}

const initialState: State = {
    metric: null,
    values: [],
    dateTimePeriod: {
        period: 'hour',
        start: DateTime.now().minus({ hour: 1 }).set({ millisecond: 0 }).toISO(),
        end: DateTime.now().set({ millisecond: 0 }).toISO()
    },
    isAggregated: false,
    graphValue: null
};

export const reducer = createReducer(
    initialState,
    on(SearchActions.exploreMetric, (state, { metric }): State => ({ ...state, metric })),
    on(ExplorerActions.getRangeSucces, (state, { values, isAggregated }): State => ({ ...state, values, isAggregated })),
    on(ExplorerActions.setDateTimePeriod, (state, { period }): State => ({
        ...state, dateTimePeriod: {
            period: period.period,
            start: period.start.toISO(),
            end: period.end.toISO()
        }
    })),
    on(ExplorerActions.setGraphValue, (state, { value }): State => ({
        ...state, graphValue: {
            value: value.value,
            timestamp: value.timestamp.toISO(),
            annotations: value.annotations ?? []
        }
    }))
);
