import { DataStructure, IndametaItem, SearchableProperty, SearchQueryItem } from '@models/indameta-models';
import { createReducer, on } from '@ngrx/store';
import * as SearchActions from './search.actions';
import { historyIndabaEquals, historyIndametaEquals, isSearchHistoryIndaba, isSearchHistoryIndameta, SearchDisplay, SearchHistoryIndaba, SearchHistoryIndameta, SearchSource } from '@models/search-models';
import { Pagination } from '@models/api-models';
import { Metric, MetricWithValues } from '@models/indaba-models';
import { favoriteEquals, isSearchFavorite, SearchFavorite } from '@models/search-models/search-favorite';
import { DateTime } from 'luxon';

export const searchFeatureKey = 'search';

const indametaHistoryKey = 'search_indameta_history';
const indabaHistoryKey = 'search_indaba_history';
const favoriteHistoryKey = 'search_favorite_history';
const historyMaxSize = 5;

export interface State {
    searchDisplay: SearchDisplay;
    searchSource: SearchSource;
    dataStructures: DataStructure[];
    dataStructureId: string;
    searchables: SearchableProperty[];
    searchQuery: SearchQueryItem[];
    indametaMetrics: IndametaItem[];
    databases: string[];
    database: string;
    indabaFilter: string;
    indabaMetrics: Metric[];
    pagination: Pagination;
    indametaHistory: SearchHistoryIndameta[];
    indabaHistory: SearchHistoryIndaba[];
    favorites: SearchFavorite[];
    selectedMetrics: (IndametaItem | Metric)[];
    selectedFavorite: SearchFavorite;
    metricsLatests: MetricWithValues[];
}

const initialState: State = {
    searchDisplay: 'config',
    searchSource: 'indameta',
    dataStructures: [],
    dataStructureId: null,
    searchables: [],
    searchQuery: [],
    indametaMetrics: [],
    databases: [],
    database: null,
    indabaFilter: null,
    indabaMetrics: [],
    pagination: null,
    indametaHistory: initIndametaHistory(),
    indabaHistory: initIndabaHistory(),
    favorites: initFavoriteHistory(),
    selectedFavorite: null,
    selectedMetrics: [],
    metricsLatests: []
};

function initIndametaHistory(): SearchHistoryIndameta[] {
    const json = localStorage.getItem(indametaHistoryKey);
    if (!json)
        return [];

    const array = JSON.parse(json);
    if (!Array.isArray(array))
        return [];

    const history: SearchHistoryIndameta[] = [];
    for (const obj of array)
        if (isSearchHistoryIndameta(obj))
            history.push(obj);
    return history;
}

function initIndabaHistory(): SearchHistoryIndaba[] {
    const json = localStorage.getItem(indabaHistoryKey);
    if (!json)
        return [];

    const array = JSON.parse(json);
    if (!Array.isArray(array))
        return [];

    const history: SearchHistoryIndaba[] = [];
    for (const obj of array)
        if (isSearchHistoryIndaba(obj))
            history.push(obj);
    return history;
}

function initFavoriteHistory(): SearchFavorite[] {
    const json = localStorage.getItem(favoriteHistoryKey);
    if (!json)
        return [];

    const array = JSON.parse(json);
    if (!Array.isArray(array))
        return [];

    const favorite: SearchFavorite[] = [];
    for (const obj of array)
        if (isSearchFavorite(obj))
            favorite.push(obj);
    return favorite;
}

export const reducer = createReducer(
    initialState,
    on(SearchActions.setSearchDisplay, (state, { searchDisplay }): State => ({ ...state, searchDisplay })),
    on(SearchActions.resetSearchDisplay, (state): State => ({ 
        ...initialState,
        favorites: state.favorites,
        indametaHistory: state.indametaHistory,
        indabaHistory: state.indabaHistory
    })),
    on(SearchActions.setSearchSource, (state, { searchSource }): State => ({ ...state, searchSource })),
    on(SearchActions.getDataSturcturesSuccess, (state, { dataStructures }): State => ({ ...state, dataStructures })),
    on(SearchActions.setDataStructure, (state, { dataStructure }): State => ({
        ...state,
        dataStructureId: dataStructure.id,
        searchables: [],
        searchQuery: [],
        indametaMetrics: [],
        searchDisplay: 'config',
        pagination: null
    })),
    on(SearchActions.getSearchablesSuccess, (state, { searchables }): State => ({ ...state, searchables })),
    on(SearchActions.addSearchQueryItem, (state, { searchQueryItem }): State => ({ ...state, searchQuery: [...state.searchQuery, searchQueryItem] })),
    on(SearchActions.removeSearchQueryItem, (state, { searchQueryItem }): State => ({
        ...state,
        searchQuery: [...state.searchQuery.filter(sqi => sqi !== searchQueryItem)]
    })),
    on(SearchActions.removeMetricFromSelection, (state, { metric }): State => ({
        ...state,
        metricsLatests: [...state.metricsLatests.filter(sm => sm.name !== metric.name)],
        selectedMetrics: [...state.selectedMetrics.filter(sm => sm.label !== metric.name)]
    })),
    on(SearchActions.searchMetricsIndaMetaSuccess, (state, { indametaMetrics, pagination, history }): State => {
        const existingHistory = state.indametaHistory.find(hist => historyIndametaEquals(hist, history));
        let indametaHistory: SearchHistoryIndameta[] = [];
        if (existingHistory)
            indametaHistory = [...new Set([existingHistory, ...state.indametaHistory])].slice(0, historyMaxSize);
        else
            indametaHistory = [history, ...state.indametaHistory].slice(0, historyMaxSize);
        localStorage.setItem(indametaHistoryKey, JSON.stringify(indametaHistory));

        return {
            ...state,
            indametaMetrics,
            pagination,
            indametaHistory,
            searchDisplay: 'results'
        };
    }),
    on(SearchActions.getNextMetricsIndaMetaSuccess, (state, { indametaMetrics, pagination }): State => ({
        ...state,
        indametaMetrics: [...state.indametaMetrics, ...indametaMetrics],
        pagination
    })),
    on(SearchActions.getDatabasesSuccess, (state, { databases }): State => ({ ...state, databases })),
    on(SearchActions.setDatabase, (state, { database }): State => ({
        ...state,
        database,
        indabaFilter: null,
        indabaMetrics: [],
        searchDisplay: 'config'
    })),
    on(SearchActions.setIndabaFilter, (state, { indabaFilter }): State => ({ ...state, indabaFilter, searchDisplay: 'config' })),
    on(SearchActions.searchMetricsIndabaSuccess, (state, { indabaMetrics, pagination, history }): State => {
        const existingHistory = state.indabaHistory.find(hist => historyIndabaEquals(hist, history));
        let indabaHistory: SearchHistoryIndaba[] = [];
        if (existingHistory)
            indabaHistory = [...new Set([existingHistory, ...state.indabaHistory])].slice(0, historyMaxSize);
        else
            indabaHistory = [history, ...state.indabaHistory].slice(0, historyMaxSize);
        localStorage.setItem(indabaHistoryKey, JSON.stringify(indabaHistory));

        return {
            ...state,
            indabaMetrics,
            pagination,
            indabaHistory,
            searchDisplay: 'results'
        };
    }),
    on(SearchActions.updateFavorite, (state, { favorite, newMetrics }): State => {
        const existingFavoriteIndex = state.favorites.findIndex(fav => favoriteEquals(fav, favorite));
        let favorites = state.favorites;        

        if(existingFavoriteIndex < 0) {
            return state;
        }else{
            const newFavorites = [...state.favorites];
            const newFavorite = {...favorite, metrics: newMetrics, lastVisit: DateTime.now().toISO()};
            newFavorites.splice(existingFavoriteIndex, 1, newFavorite);
            favorites = newFavorites;
        }
        
        localStorage.setItem(favoriteHistoryKey, JSON.stringify(favorites));

        return {
            ...state,
            favorites: favorites,
            selectedFavorite: favorite
        };
    }),
    on(SearchActions.deleteFavorite, (state, { favorite }): State => {
        const existingFavoriteIndex = state.favorites.findIndex(fav => favoriteEquals(fav, favorite));
        let favorites = state.favorites;        

        if(existingFavoriteIndex < 0) {
            return state;
        }else{
            const newFavorites = [...state.favorites];
            newFavorites.splice(existingFavoriteIndex, 1);
            favorites = newFavorites;
        }
        
        localStorage.setItem(favoriteHistoryKey, JSON.stringify(favorites));

        return {
            ...state,
            favorites: favorites,
            selectedFavorite: favorite
        };
    }),
    on(SearchActions.saveToFavorite, (state, { favorite }): State => {
        const existingFavoriteIndex = state.favorites.findIndex(fav => favoriteEquals(fav, favorite));
        
        let favorites = state.favorites;        

        if(existingFavoriteIndex < 0) {
            favorites = [favorite, ...state.favorites];
        }else{
            const newFavorites = [...state.favorites];
            newFavorites.splice(existingFavoriteIndex, 1, favorite);
            favorites = newFavorites;
        }
        
        localStorage.setItem(favoriteHistoryKey, JSON.stringify(favorites));

        return {
            ...state,
            favorites: favorites,
            selectedFavorite: favorite
        };
    }),
    on(SearchActions.getNextMetricsIndabaSuccess, (state, { indabaMetrics, pagination }): State => ({
        ...state,
        indabaMetrics: [...state.indabaMetrics, ...indabaMetrics],
        pagination
    })),
    on(SearchActions.searchFromHistoryIndaMeta, (state, { history }): State => ({
        ...state,
        dataStructureId: history.dataStructure.id,
        searchQuery: history.query
    })),
    on(SearchActions.searchFromHistoryIndaba, (state, { history }): State => ({
        ...state,
        database: history.database,
        indabaFilter: history.filter
    })),
    on(SearchActions.selectMetric, (state, { metric }): State => {
        const selectedMetric = state.selectedMetrics.findIndex(sm => sm.id === metric.id);
        if (selectedMetric === -1)
            return { ...state, selectedMetrics: [...state.selectedMetrics, metric] };
        const selectedMetrics = [...state.selectedMetrics];
        selectedMetrics.splice(selectedMetric, 1);
        return { ...state, selectedMetrics };
    }),
    on(SearchActions.resetSelectedMetrics, (state): State => ({ ...state, selectedMetrics: [], selectedFavorite: null })),
    on(SearchActions.getLatestsSuccess, (state, { metricsLatests }): State => ({ ...state, metricsLatests })),
    on(SearchActions.goToFavorite, (state, { favorite }): State => {
        return { 
            ...state, 
            selectedMetrics: favorite.metrics, 
            selectedFavorite: favorite
        };
    }),
    on(SearchActions.deleteFavorite, (state, { favorite }): State => {
        const existingFavoriteIndex = state.favorites.findIndex(fav => favoriteEquals(fav, favorite));

        const newFavorites = [...state.favorites];
        
        if(existingFavoriteIndex >= 0) {
            newFavorites.splice(existingFavoriteIndex, 1);
            localStorage.setItem(favoriteHistoryKey, JSON.stringify(newFavorites));
        }

        return { 
            ...state, 
            favorites: newFavorites 
        };
    })
);
