import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { Dashboard } from '../../../shared/models/dashboard-models/dashboard';
import { DashboardType } from '@models/dashboard-models/types';
import { DashboardFilters } from '@models/dashboard-models/dashboard-filters';

export const dashboardFeatureKey = 'dashboard';

export interface State {
    dashboardType: DashboardType;
    selectedDashboard: Dashboard;
    myDashboards: Dashboard[];
    sharedDashboards: Dashboard[];
    filters: DashboardFilters;
    isFilterActivated: boolean;
}

const initialState: State = {
    dashboardType: 'myDashboards',
    selectedDashboard: null,
    myDashboards: [],
    sharedDashboards: [],
    filters: {
        favorites: false,
        search: ''
    },
    isFilterActivated: false
};

export const reducer = createReducer(
    initialState,
    on(DashboardActions.setDashboard, (state, { dashboard }): State => ({ ...state, selectedDashboard: dashboard })),
    on(DashboardActions.getDashboardsSuccess, (state, { myDashboards, sharedDashboards }): State => ({
        ...state,
        myDashboards,
        sharedDashboards
    })),
    on(DashboardActions.setDashboardType, (state, { dashboardType }): State => ({ ...state, dashboardType })),
    on(DashboardActions.setFilters, (state, { filters }): State => ({ ...state, filters, isFilterActivated: (filters.favorites || filters.search !== '') }))
);
