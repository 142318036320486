import { MetricForm, MetricInputApi } from "@models/metric-input-models";
import { createReducer, on } from "@ngrx/store";
import * as FormsActions from "./forms.actions";
import { CoreActions } from "../../core/store";
import { MetricInputApiCache } from "@models/metric-input-models/metric-inputs-cache";

export const formsFeatureKey = 'forms';

const preferredCameraIdKey = "preferred_camera";

export interface State {
    metricInput: MetricInputApi;
    metricsInCache: MetricInputApiCache[];
    canSaveNewValueMetric: boolean;
    preferredCameraId: string;
    synchronizeOffline: boolean;
    showSynchronizeModal: boolean;
}

const initialState: State = {
    metricInput: null,
    metricsInCache: [],
    canSaveNewValueMetric: false,
    synchronizeOffline: false,
    showSynchronizeModal: true,
    preferredCameraId: localStorage.getItem(preferredCameraIdKey)
};

export const reducer = createReducer(
    initialState,
    on(CoreActions.loadFormsEntriesInCacheSuccess, (state, { forms }): State => {
        return { 
            ...state, 
            metricsInCache: forms
        };
    }),
    on(FormsActions.setPreferredCamera, (state, { preferredCameraId }): State => {
        localStorage.setItem(preferredCameraIdKey, preferredCameraId);
        return { ...state, preferredCameraId: preferredCameraId };
    }),
    on(FormsActions.getFormByIdSuccess, (state, { form }): State => {
        return ({ ...state, metricInput: form, canSaveNewValueMetric: false });
    }),
    on(FormsActions.showSynchronizeModal, (state): State => {
        return ({ ...state, showSynchronizeModal: false });
    }),
    on(FormsActions.addToCacheSuccess, (state, { metricInput }): State => {
        return {
            ...state,
            metricsInCache: [...state.metricsInCache, metricInput]
        };
    }),
    on(FormsActions.removeFormsFromCacheSuccess, (state): State => {
        return {
            ...state,
            metricsInCache: [],
            synchronizeOffline: false,
            showSynchronizeModal: false
        };
    }),
    on(FormsActions.saveNewValueMetricsCache, (state): State => {
        return {
            ...state,
            synchronizeOffline: true
        };
    }),
    on(FormsActions.removeFormFromCacheSuccess, (state, { metric }): State => {
        return {
            ...state,
            metricsInCache: state.metricsInCache.filter(m => m.cacheId !== metric.id)
        };
    }),
    on(FormsActions.getLatestSuccess, (state, { metrics }): State => {
        const metricWithValues: MetricForm[] = state.metricInput.metrics.map(m => {
            const metric = metrics.find(x => x.name === m.name && x.datasource === m.datasource);
            if (metric) {
                const value = metric.values?.length > 0 ? metric.values[0] : null;
                return { ...m, oldValue: value };
            }

            return { ...m };
        });
        const metricInput = { ...state.metricInput, metrics: metricWithValues };
        return { ...state, metricInput };
    }),
    on(FormsActions.updateValueMetricSuccess, (state, { index, newValue }): State => {
        const metrics = [...state.metricInput.metrics];
        metrics[index] = { ...newValue };
        const metricInput = { ...state.metricInput, metrics };

        return { ...state, metricInput, canSaveNewValueMetric: true };
    }),
    on(FormsActions.saveSuccess, (state): State => {

        const metrics = state.metricInput.metrics
            .map(mf => {
                if (mf.newValue)
                    return { ...mf, newValue: null };
                else
                    return mf;
            });

        const metricInput = { ...state.metricInput, metrics };
        return { ...state, metricInput, canSaveNewValueMetric: false };
    })
);
