import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { State, alertingFeatureKey } from './alerting.reducer';
import { AlarmHistory, AlarmReport, AlarmType } from '@models/alerting-models';
import { DateTimePeriod } from '@models/dateTimePeriod';
import { DateTime } from 'luxon';

const selectAlertingState = createFeatureSelector<State>(alertingFeatureKey);

// Raw selectors
export const selectAlarmType = createSelector(selectAlertingState, (state: State) => state.alarmType);

export const selectDateTimePeriodString = createSelector(selectAlertingState, (state: State) => state.dateTimePeriod);
export const selectDateTimePeriod = createSelector<object, State, DateTimePeriod>(selectAlertingState, (state: State) => ({
    period: state.dateTimePeriod.period,
    start: DateTime.fromISO(state.dateTimePeriod.start),
    end: DateTime.fromISO(state.dateTimePeriod.end)
}));

export const selectIsUserOnCall = createSelector(selectAlertingState, (state: State) => state.isOnCall);

export const selectAlarmFilters = createSelector(selectAlertingState, (state: State) => state.filters);

export const selectAlarmsHistory = createSelector(selectAlertingState, (state: State) => {
    return state.alarmsHistory;
});

export const selectOnCallAlarmsHistory = createSelector(selectAlertingState, (state: State) => {
    return state.onCallAlarmsHistory;
});


export const selectAlarmsHistoryPagination = createSelector(selectAlertingState, (state: State) => state.alarmsPagination);
export const selectAlarmsHasMore = createSelector(selectAlertingState, (state: State) => state.alarmsPagination?.canNextPagination ?? false);

export const selectOnCallAlarmsHistoryPagination = createSelector(selectAlertingState, (state: State) => state.onCallPagination);
export const selectOnCallHasMore = createSelector(selectAlertingState, (state: State) => state.onCallPagination?.canNextPagination ?? false);
export const selectIsFilterActivated = createSelector(selectAlertingState, (state: State) => state.isFilterActivated);

// Combined selectors

export const selectHistoryById = (historyId: string, alarmType: AlarmType): MemoizedSelector<object, AlarmHistory, (s1: AlarmHistory[]) => AlarmHistory> =>
    createSelector(
        alarmType === 'Alarm' ? selectAlarmsHistory : selectOnCallAlarmsHistory,
        (history) => history.find(h => h.id === historyId)
    );

export const selectHistoryReport = (historyId: string, alarmType: AlarmType): MemoizedSelector<object, AlarmReport[], (s1: AlarmHistory) => AlarmReport[]> =>
    createSelector(
        selectHistoryById(historyId, alarmType),
        (history) => history?.reports ?? []
    );
