import { createReducer, on } from '@ngrx/store';
import * as AlertingActions from './alerting.actions';
import { AlarmHistory, AlarmType } from '@models/alerting-models';
import { InfoPagination } from '@models/api-models';
import { DateTimePeriodString } from '@models/dateTimePeriod';
import { DateTime } from 'luxon';
import { AlarmFilters } from '@models/alerting-models/alarm-filters';

export const alertingFeatureKey = 'alerting';

export interface State {
    alarmType: AlarmType;
    isOnCall: boolean;
    alarmsHistory: AlarmHistory[];
    alarmsPagination: InfoPagination;
    onCallAlarmsHistory: AlarmHistory[];
    onCallPagination: InfoPagination;
    dateTimePeriod: DateTimePeriodString;
    filters: AlarmFilters;
    isFilterActivated: boolean;
}

const initialState: State = {
    alarmType: 'Alarm',
    isOnCall: null,
    alarmsHistory: [],
    alarmsPagination: null,
    onCallAlarmsHistory: [],
    onCallPagination: null,
    dateTimePeriod: {
        period: 'hour',
        start: DateTime.now().minus({ hour: 1 }).set({ millisecond: 0 }).toISO(),
        end: DateTime.now().set({ millisecond: 0 }).toISO()
    },
    filters: {
        withReports: false
    },
    isFilterActivated: false
};

export const reducer = createReducer(
    initialState,
    on(AlertingActions.setAlarmType, (state, { alarmType }): State => ({ ...state, alarmType })),
    on(AlertingActions.setDateTimePeriod, (state, { dateTimePeriod }): State => ({
        ...state, dateTimePeriod: {
            period: dateTimePeriod.period,
            start: dateTimePeriod.start.toISO(),
            end: dateTimePeriod.end.toISO()
        }
    })),
    on(AlertingActions.isUserOnCallSuccess, (state, { isOnCall }): State => ({ ...state, isOnCall })),
    on(AlertingActions.setAlarmFilters, (state, { filters }): State => ({ ...state, filters: filters, isFilterActivated: filters.withReports })),
    on(AlertingActions.getAlarmsHistorySuccess, (state, { alarmsHistory, pagination }): State => ({ ...state, alarmsHistory, alarmsPagination: pagination })),
    on(AlertingActions.getOnCallAlarmsHistorySuccess, (state, { onCallAlarmsHistory, pagination }): State => ({ ...state, onCallAlarmsHistory, onCallPagination: pagination })),

    on(AlertingActions.getAlarmHistoryReportsSuccess, (state, { historyId, reports }): State => {
        const alarmHistory = [...state.alarmsHistory.map(h => {
            if (h.id !== historyId)
                return h;
            return { ...h, reports };
        })];
        const onCallHistory = [...state.onCallAlarmsHistory.map(h => {
            if (h.id !== historyId)
                return h;
            return { ...h, reports };
        })];
        return { ...state, alarmsHistory: alarmHistory, onCallAlarmsHistory: onCallHistory };
    }),

    on(AlertingActions.getNextAlarmsHistorySuccess, (state, { alarmsHistory, pagination }): State => {
        const nextAlarmHistory = [...state.alarmsHistory, ...alarmsHistory];
        return { ...state, alarmsHistory: nextAlarmHistory, alarmsPagination: pagination };
    }),

    on(AlertingActions.getNextOnCallAlarmsHistorySuccess, (state, { onCallAlarmsHistory, pagination }): State => {
        const nextAlarmHistory = [...state.onCallAlarmsHistory, ...onCallAlarmsHistory];
        return { ...state, onCallAlarmsHistory: nextAlarmHistory, onCallPagination: pagination };
    })
);
