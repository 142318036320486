import { ApiResponse } from "@models/api-models";
import { AnnotationUpsert, MetricWithValues } from "@models/indaba-models";
import { MetricForm, MetricInputApi } from "@models/metric-input-models";
import { MetricInputApiSummary } from "@models/metric-input-models/metric-input-summary";
import { MetricInputApiCache } from "@models/metric-input-models/metric-inputs-cache";
import { createAction, props } from "@ngrx/store";

export const setPreferredCamera = createAction('[Forms] Set preferred camera', props<{ preferredCameraId: string }>());

export const analyzeQrCode = createAction('[Forms] Analyze QR Code from scan', props<{ qrCode: string }>());
export const analyzeQrCodeSuccess = createAction('[Forms] Analyze QR Code from scan success', props<{ metricInputId: string }>());
export const analyzeQrCodeError = createAction('[Forms] Analyze QR Code from scan error');

export const getFormById = createAction('[Forms] Get forms by id', props<{ formId: string }>());
export const getFormByIdSuccess = createAction('[Forms] Get forms by id Success', props<{ form: MetricInputApi }>());
export const getFormsByIdError = createAction('[Forms] Get forms by id Error');

export const getFormFromRoute = createAction('[Forms] Get forms from route');
export const getFormFromRouteSuccess = createAction('[Forms] Get forms from route Success');
export const getFormFromRouteError = createAction('[Forms] Get forms from route Error');

export const accesDeniedForm = createAction('[Forms] Access denied form');

export const getLatest = createAction('[Forms] Get latest');
export const getLatestSuccess = createAction('[Forms] Get latest Success', props<{ id: string, metrics: MetricWithValues[] }>());

export const openMetricModal = createAction('[Forms] Forms metric modal open', props<{ index: number, metric: MetricForm }>());

export const updateValueMetric = createAction('[Forms] Update value metrics', props<{ index: number, metric: MetricForm }>());
export const updateValueMetricSuccess = createAction('[Forms] Update value metrics Success', props<{ form: MetricInputApi, index: number, newValue: MetricForm }>());

export const saveNewValueMetrics = createAction('[Forms] Save new value metrics');
export const saveNewValueMetricsCache = createAction('[Forms] Save new value metrics synchro');
export const selectNewValueMetrics = createAction('[Forms] Select new value metrics', props<{ metrics: MetricInputApi[] }>());
export const selectNewValueMetricsSummary = createAction('[Forms] Select new value metrics summary');


export const showSynchronizeModal = createAction('[Forms] Show synchronize modal');
export const viewSynchronize = createAction('[Forms] Go to synchronize page');
export const removeFormFromCache = createAction('[Forms] Remove form from cache', props<{ metric: MetricInputApiSummary }>());
export const removeFormFromCacheSuccess = createAction('[Forms] Remove form from cache Success', props<{ metric: MetricInputApiSummary }>());
export const removeFormsFromCacheSuccess = createAction('[Forms] Remove forms from cache success');

export const saveNewValueMetricsSuccess = createAction('[Forms] Save new value metrics Success', props<{ result: { write: ApiResponse<void>, datasource: string }[], annotations: AnnotationUpsert[] }>());
export const saveNewValueMetricsSynchroSuccess = createAction('[Forms] Save new value metrics cache Success', props<{ result: { write: ApiResponse<void>, datasource: string }[], annotations: AnnotationUpsert[] }>());
export const saveSuccess = createAction('[Forms] Save Success');
export const saveSuccessCache = createAction('[Forms] Save Success cache');
export const addToCacheSuccess = createAction('[Forms] Add to cache success', props<{ metricInput: MetricInputApiCache }>());
export const saveAnnotationError = createAction('[Forms] Save annotation Error');

