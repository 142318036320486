import { MetricInputApiCache } from '@models/metric-input-models/metric-inputs-cache';
import { Me, Preferences } from '@models/ums-models';
import { createAction, props } from '@ngrx/store';

export const getMe = createAction('[Core] Get me');
export const getMeSuccess = createAction('[Core] Get me Success', props<{ me: Me }>());
export const getMeError = createAction('[Core] Get me Error');

export const getPreferences = createAction('[Core] Get preferences');
export const getPreferencesSuccess = createAction('[Core] Get preferences Success', props<{ preferences: Preferences }>());
export const getPreferencesError = createAction('[Core] Get preferences Error');

export const updatePreferences = createAction('[Core] Update preferences', props<{ preferences: Preferences }>());
export const updatePreferencesSuccess = createAction('[Core] Update preferences Success', props<{ preferences: Preferences }>());
export const updatePreferencesError = createAction('[Core] Update preferences Error');

export const updateLanguageSuccess = createAction('[Core] Update language Success');
export const updateLanguageOffline = createAction('[Core] Update language Offline', props<{ preferences: Preferences }>());

export const showSpinner = createAction('[Core] Show spinner');
export const hideSpinner = createAction('[Core] Hide spinner');

export const logout = createAction('[Core] Logout');

export const changedNetworkInformation = createAction('[Core] Changed network informations');
export const changedToOffline = createAction('[Core] Changed to offline');
export const changedToOnline = createAction('[Core] Changed to online');

export const setTitle = createAction('[Core] Set title', props<{ title: string }>());

export const initFormsInCache = createAction('[Core] Init form in cache');
export const loadFormsEntriesInCacheSuccess = createAction('[Core] Load forms entries in cache success', props<{ forms: MetricInputApiCache[] }>());

export const goBackNavigation = createAction('[Core] Go back to previous page');
