import { createAction, props } from '@ngrx/store';
import { Dashboard } from '../../../shared/models/dashboard-models/dashboard';
import { DashboardType } from '@models/dashboard-models/types';
import { DashboardFilters } from '@models/dashboard-models/dashboard-filters';

export const getDashboards = createAction('[Dashboard] Get dashboards');
export const getDashboardsSuccess = createAction('[Dashboard] Get dashboards success', props<{ myDashboards: Dashboard[], sharedDashboards: Dashboard[] }>());
export const getDashboardsError = createAction('[Dashboard] Get dashboards error');

export const setDashboard = createAction('[Dashboard] Set dashboard', props<{ dashboard: Dashboard }>());

export const setDashboardType = createAction('[Dashboard] Set dashboard type', props<{ dashboardType: DashboardType }>());

export const setFilters = createAction('[Dashboard] Set dashboard filters', props<{ filters: DashboardFilters }>());
