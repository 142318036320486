import { DateTimePeriod } from '@models/dateTimePeriod';
import { Aggregation, Annotation, AnnotationUpsert, Value } from '@models/indaba-models';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

export const getRange = createAction('[Explorer] Get metric range', props<{ aggregation: Aggregation }>());
export const getRangeSucces = createAction('[Explorer] Get metric range Success', props<{ values: Value[], isAggregated: boolean }>());
export const getRangeError = createAction('[Explorer] Get metric range Error');

export const getInstant = createAction('[Epxlorer] Get metric instant', props<{ timestamp: DateTime }>());
export const getInstantSucces = createAction('[Epxlorer] Get metric instant Success', props<{ value: Value }>());
export const getInstantError = createAction('[Epxlorer] Get metric instant Error');

export const setDateTimePeriod = createAction('[Explorer] Set datetime period', props<{ period: DateTimePeriod }>());

export const setGraphValue = createAction('[Explorer] Set graph value', props<{ value: Value }>());

export const deleteAnnotation = createAction('[Explorer] Delete annotation', props<{ annotation: Annotation }>());
export const deleteAnnotationError = createAction('[Explorer] Delete annotatinon Error');

export const upsertAnnotation = createAction('[Explorer] Upsert annotation', props<{ annotation: AnnotationUpsert }>());
export const upsertAnnotationError = createAction('[Explorer] Upsert annotatinon Error');
