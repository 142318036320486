import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data    
    selectRouteDataParam, // select a specific param from the route data
    selectUrl, // select the current url
    selectTitle // select the current title
} = getRouterSelectors(selectRouter);
