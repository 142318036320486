import { createReducer, on } from '@ngrx/store';
import * as CoreActions from './core.actions';
import { Me, Preferences } from '@models/ums-models';

export const coreFeatureKey = 'core';

export interface State {
    me: Me,
    preferences: Preferences,
    showSpinner: number,
    networkInfos: NetworkInformationSerializable,
    title: string,
    offline: boolean
}

const initialState: State = {
    me: null,
    preferences: null,
    showSpinner: 0,
    networkInfos: null,
    title: '',
    offline: false
};

export const reducer = createReducer(
    initialState,
    on(CoreActions.getMeSuccess, (state, { me }): State => ({ ...state, me })),
    on(CoreActions.getPreferencesSuccess, (state, { preferences }): State => ({ ...state, preferences })),
    on(CoreActions.updatePreferencesSuccess, (state, { preferences }): State => ({ ...state, preferences })),
    on(CoreActions.updateLanguageOffline, (state, { preferences }): State => ({ ...state, preferences })),
    on(CoreActions.showSpinner, (state): State => ({ ...state, showSpinner: state.showSpinner + 1 })),
    on(CoreActions.hideSpinner, (state): State => ({ ...state, showSpinner: state.showSpinner - 1 })),
    on(CoreActions.changedNetworkInformation, (state): State => {
        const connection = navigator.connection;
        const networkInfos: NetworkInformationSerializable = {
            downlink: connection.downlink,
            downlinkMax: connection.downlinkMax,
            effectiveType: connection.effectiveType,
            rtt: connection.rtt,
            saveData: connection.saveData,
            type: connection.type
        };
        return { ...state, networkInfos };
    }),
    on(CoreActions.changedToOffline, (state): State => ({ ...state, offline: true })),
    on(CoreActions.changedToOnline, (state): State => ({ 
        ...state, 
        offline: false,
        showSpinner: 0
    })),
    on(CoreActions.setTitle, (state, { title }): State => ({ ...state, title }))
);
