import { DataStructure, SearchQueryItem } from '@models/indameta-models';

export interface SearchHistoryIndameta {
    dataStructure: DataStructure;
    query: SearchQueryItem[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSearchHistoryIndameta(arg: any): arg is SearchHistoryIndameta {
    return arg.dataStructure && arg.dataStructure.id && typeof arg.dataStructure.id === 'string' && arg.query && Array.isArray(arg.query);
}

export function historyIndametaEquals(history1: SearchHistoryIndameta, history2: SearchHistoryIndameta): boolean {
    if (history1.dataStructure.id !== history2.dataStructure.id)
        return false;

    if (history1.query.length !== history2.query.length)
        return false;

    for (const sqi1 of history1.query) {
        const sqi2 = history2.query.find(sqi => sqi.searchTerm === sqi1.searchTerm && sqi.searchable.typeId === sqi1.searchable.typeId
            && sqi.searchable.displayName === sqi1.searchable.displayName);
        if (!sqi2)
            return false;
    }
    return true;
}

export interface SearchHistoryIndaba {
    database: string;
    filter: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSearchHistoryIndaba(arg: any): arg is SearchHistoryIndaba {
    return arg.database && typeof arg.database === 'string' && ((arg.filter && typeof arg.filter === 'string') || arg.filter === null);
}

export function historyIndabaEquals(history1: SearchHistoryIndaba, history2: SearchHistoryIndaba): boolean {
    return history1.database === history2.database && history1.filter === history2.filter;
}
