import { AlarmHistory, AlarmType, ReportsModalDisplay, AlarmReport, AlarmReportUpsert } from '@models/alerting-models';
import { AlarmFilters } from '@models/alerting-models/alarm-filters';
import { InfoPagination } from '@models/api-models';
import { DateTimePeriod } from '@models/dateTimePeriod';
import { createAction, props } from '@ngrx/store';

export const setAlarmType = createAction('[Alerting] Set alarm type', props<{ alarmType: AlarmType }>());

export const setDateTimePeriod = createAction('[Alerting] Set alarm datetime period', props<{ dateTimePeriod: DateTimePeriod }>());

export const isUserOnCall = createAction('[Alerting] Is user on-call');
export const isUserOnCallSuccess = createAction('[Alerting] Is user on-call Success', props<{ isOnCall: boolean }>());

export const getAlarmsHistory = createAction('[Alerting] Get alarms history');
export const getAlarmsHistorySuccess = createAction('[Alerting] Get alarms history Success', props<{ alarmsHistory: AlarmHistory[], pagination: InfoPagination }>());
export const getAlarmsHistoryError = createAction('[Alerting] Get alarms history Error');

export const getOnCallAlarmsHistory = createAction('[Alerting] Get on-call alarms history');
export const getOnCallAlarmsHistorySuccess = createAction('[Alerting] Get on-call alarms history Success', props<{ onCallAlarmsHistory: AlarmHistory[], pagination: InfoPagination }>());
export const getOnCallAlarmsHistoryError = createAction('[Alerting] Get on-call alarms history Error');

export const getNextHistoryDispatcher = createAction('[Alerting] Get next history dispatcher');

export const getNextAlarmsHistory = createAction('[Alerting] Get next alarms history');
export const getNextAlarmsHistorySuccess = createAction('[Alerting] Get next alarms history Success', props<{ alarmsHistory: AlarmHistory[], pagination: InfoPagination }>());
export const getNextAlarmsHistoryError = createAction('[Alerting] Get next alarms history Error');

export const getNextOnCallAlarmsHistory = createAction('[Alerting] Get next on-call alarms history');
export const getNextOnCallAlarmsHistorySuccess = createAction('[Alerting] Get next on-call alarms history Success', props<{ onCallAlarmsHistory: AlarmHistory[], pagination: InfoPagination }>());
export const getNextOnCallAlarmsHistoryError = createAction('[Alerting] Get next on-call alarms history Error');

export const openReportsModal = createAction('[Alerting] Reports modal Open', props<{ historyId: string, display: ReportsModalDisplay }>());

export const getAlarmHistoryReports = createAction('[Alerting] Get alarm history reports', props<{ historyId: string }>());
export const getAlarmHistoryReportsSuccess = createAction('[Alerting] Get alarm history reports Success', props<{ historyId: string, reports: AlarmReport[] }>());
export const getAlarmHistoryReportsError = createAction('[Alerting] Get alarm history reports Error');

export const addReport = createAction('[Alerting] Add report', props<{ historyId: string, report: AlarmReportUpsert }>());
export const addReportError = createAction('[Alerting] Add report Error');

export const editReport = createAction('[Alerting] Edit report', props<{ historyId: string, reportId: string, report: AlarmReportUpsert }>());
export const editReportError = createAction('[Alerting] Edit report Error');

export const deleteReport = createAction('[Alerting] Delete report', props<{ historyId: string, report: AlarmReport }>());
export const deleteReportError = createAction('[Alerting] Delete report Error');

export const setAlarmFilters = createAction('[Alerting] Set alarm filters', props<{ filters: AlarmFilters }>());
