import { MetricWithValues } from "@models/indaba-models";

export interface SearchFavorite {
    name: string;
    metrics: MetricWithValues[];
    lastVisit: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSearchFavorite(arg: any): arg is SearchFavorite {
    return arg.name && typeof arg.name === 'string' && (arg.metrics && typeof arg.metrics === 'object' || arg.metrics === null);
}

export function favoriteEquals(fav1: SearchFavorite, newFavorite: SearchFavorite): boolean {
    return fav1.name === newFavorite.name;
}