import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, coreFeatureKey } from './core.reducer';
import * as fromRouter from 'src/app/store/router.selector.store';
import { RouterReducerState } from '@ngrx/router-store';

const selectCoreState = createFeatureSelector<State>(coreFeatureKey);

// Raw selectors
export const selectMe = createSelector(selectCoreState, (state: State) => state.me);

export const selectPreferences = createSelector(selectCoreState, (state: State) => state.preferences);

export const selectLanguage = createSelector(selectCoreState, (state: State) => state.preferences?.language);

export const selectIsOffline = createSelector(selectCoreState, (state: State) => {
    if (!state.networkInfos)
        return false;

    if(state.offline)
        return true;

    return state.networkInfos.rtt > 1000 || state.networkInfos.downlink < 0.35;
});

export const selectShowSpinner = createSelector(
    selectCoreState,
    selectIsOffline, 
    (state: State, isOffline: boolean) => state.showSpinner > 0 && !isOffline
);

export const selectShowBadNetwork = createSelector(selectCoreState, (state: State) => {
    if (!state.networkInfos)
        return false;

    if(state.offline)
        return true;

    return state.networkInfos.rtt > 500 || state.networkInfos.downlink <= 1 || state.networkInfos.effectiveType !== '4g';
});

export const selectTitle = createSelector(selectCoreState, (state: State) => state.title);

export const selectGoBackRoute = createSelector(
    fromRouter.selectRouteParam('formId'),
    fromRouter.selectRouter,
    (formId: string, router: RouterReducerState) => {
        if (formId)
            return 'forms';
        if (router.state.url === '/explorer/search/compare')
            return 'explorer/search';
        if (router.state.url === '/explorer')
            return 'explorer/search/compare';
        if (router.state.url === '/dashboard/view')
            return 'dashboard';
        return null;
    }
);

export const selectShowGoBack = createSelector(
    selectGoBackRoute,
    (route: string) => !!route
);
